import BlockquoteComponent from '@pretto/bricks/website/shared/components/Blockquote'

import PropTypes from 'prop-types'

import * as S from '../styles'

const Blockquote = ({ children, ...props }) => (
  <S.BlockLarge>
    <BlockquoteComponent {...props}>{children}</BlockquoteComponent>
  </S.BlockLarge>
)

Blockquote.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Blockquote
